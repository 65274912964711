import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Rating from './rating'
import Airtable from 'airtable'
import ButtonWithLoading from './ButtonWithLoading'

const ReviewForm = ({ schoolId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formData, setFomData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isReviewSubmitted, setReviewSubmitted] = useState(false)

  const handleSubmit = event => {
    setIsLoading(true)
    event.preventDefault()
    const base = Airtable.base('appHPYqFTmPXbeBvB')
    base('zaragoza_schools_reviews').create([
      {
        fields: {
          reviewerName: formData.reviewerName,
          comments: formData.comments,
          schoolBaseLink: [schoolId],
          rating: formData.rating,
          reviewerMail: formData.reviewerMail,
          kidAge: formData.kidAge,
        }
      }
    ], (err, records) => {
      if (err) {
        console.error(err)
      }

      setIsLoading(false)
      setReviewSubmitted(true)
    })
  }

  const handleInputChange = (event) => {
    setFomData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  return (
    <div>
      <button
        className='cta'
        onClick={() => setIsModalOpen(current => !current)}
      >
        Escribir opinión
      </button>
      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <button
              className='modal-hide'
              onClick={() => {
                setIsModalOpen(current => !current)
                setReviewSubmitted(false)
              }}
            >
              ✕
            </button>
            {isReviewSubmitted
              ? (
                <div className='text-center'>
                  <h2>¡Gracias por tu opinión!</h2>
                  <p>En breves recibirás un email de validación</p>
                </div>)
              : (
                <form className='review-form' onSubmit={handleSubmit}>
                  <div className='question'>
                    <p className='title'>
                      1. ¿Cuál es tu nombre?
                    </p>
                    <p className='description'>
                      Aparecerá visible junto a tu opinión sobre el colegio
                    </p>
                    <input type='text' name='reviewerName' onChange={handleInputChange} required />
                  </div>
                  <div className='question'>
                    <p className='title'>
                      2. Grado de satisfacción con el colegio
                    </p>
                    <Rating
                      size={40}
                      name='rating'
                      value={formData.rating}
                      edit
                      half={false}
                      onChange={newRating => setFomData({
                        ...formData,
                        rating: newRating
                      })}
                    />
                  </div>
                  <div className='question'>
                    <p className='title'>
                      3. Comentarios sobre el colegio
                    </p>
                    <textarea name='comments' onChange={handleInputChange} rows='5' required />
                  </div>
                  <div className='question'>
                    <p className='title'>
                      4. ¿En qué curso se encuentran tu hijo/a?
                    </p>
                    <select name='kidAge' onChange={handleInputChange} required>
                      <option value=''>- Selecciona -</option>
                      <option value='Infantil'>Infantil</option>
                      <option value='Primaria'>Primaria</option>
                      <option value='Secundaria'>Secundaria</option>
                      <option value='Bachillerato'>Bachillerato</option>
                      <option value='Ex-alumno'>Ex-alumno</option>
                    </select>
                  </div>
                  <div className='question'>
                    <p className='title'>
                      5. Email
                    </p>
                    <p className='description'>
                      NO Visible, sólo lo utilizaremos para validar que has sido tú quien ha respondido al cuestionario.
                    </p>
                    <input type='email' name='reviewerMail' onChange={handleInputChange} required />
                  </div>
                  <div className='buttons'>
                    <ButtonWithLoading
                      className='cta'
                      type='submit'
                      isLoading={isLoading}
                    >
                      Enviar opinión
                    </ButtonWithLoading>
                  </div>
                </form>
              )}
          </div>
        </div>
      )}
    </div>
  )
}

ReviewForm.propTypes = {
  schoolId: PropTypes.string.isRequired,
}

export default ReviewForm
