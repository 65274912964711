import React from 'react'
import PropTypes from 'prop-types'
import ReactStars from 'react-stars'

const Rating = ({ className, size, value, edit, ...otherProps }) => (
  <ReactStars
    value={isNaN(value) ? 0 : Math.round(value * 2) / 2}
    size={size}
    edit={edit}
    color1='#d2d2d2'
    className={`rating ${className}`}
    {...otherProps}
  />
)

Rating.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  value: PropTypes.number,
  edit: PropTypes.bool,
  otherProps: PropTypes.object
}

Rating.defaultProps = {
  className: '',
  size: 24,
  value: 0,
  edit: false
}

export default Rating
