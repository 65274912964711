import React from 'react'
import PropTypes from 'prop-types'
import Rating from './rating'
import { monthNames } from '../utils/dates'

const Review = ({ review }) => {
  const date = new Date(review.data.date)
  const monthStr = monthNames[date.getMonth()]
  var reviewDateStr = `${monthStr} de ${date.getFullYear()}`
  return (
    <li className='review'>
      <div className='review-name'>{review.data.reviewerName}</div>
      <div className='review-value'>
        <Rating size={16} value={Number(review.data.rating)} />
      </div>
      <div className='review-education'>{review.data.kidAge}</div>
      <p className='review-comment'>{review.data.comments}</p>
      <div className='review-date'>{reviewDateStr}</div>
    </li>
  )
}

Review.propTypes = {
  review: PropTypes.object.isRequired,
}

export default Review
