import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Svg from 'react-inlinesvg'
import Map from '../components/googleMaps'
import Layout from '../components/layout'
import Rating from '../components/rating'
import Review from '../components/review'
import ReviewForm from '../components/reviewForm'
import { capitalize, normalizeString } from '../utils/strings'
import Seo from '../components/seo'

const ConditionalLink = ({ children, condition, href }) =>
  condition && (
    <li className='flex'>
      <a
        className='no-link'
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        {children}
      </a>
    </li>
  )

ConditionalLink.propTypes = {
  condition: PropTypes.any,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

ConditionalLink.defaultProps = {
  condition: false,
}

const SchoolDetail = ({ data }) => {
  const school = data.airtable.data

  return (
    <Layout>
      <Seo title={`${school.name} ${school.city}, datos y opiniones`} />
      <div className='school-detail'>
        <div className='breadcrumbs'>
          <Link href='/'>{'<'} Volver al listado</Link>
        </div>
        <div className='school-header'>
          <div className='school-info'>
            <div className='school-title'>
              <h1 className='school-name'>{capitalize(school.name)}</h1>
              <div className='school-funding'>
                <span
                  className={`funding-type tag ${normalizeString(
                    school.fundingType
                  )}`}
                >
                  {capitalize(school.fundingType)}
                </span>
              </div>
            </div>
            <div className='subtitle-rating'>
              <Rating value={Number(school.avgApprovedReviews)} />
              <a href='#school-reviews'>ver opiniones</a>
            </div>
            <address className='school-address'>
              {school.address}, {school.postalCode} {school.city}
            </address>
            <ul className='school-contact'>
              <ConditionalLink
                condition={school.phone}
                href={`tel:${school.phone}`}
              >
                <div className='contact-item-container'>
                  <Svg src='/images/icon-phone_outline-24px.svg' />
                  <span className='school-contact-underline'>
                    {school.phone}
                  </span>
                </div>
              </ConditionalLink>
              <ConditionalLink
                condition={school.email}
                href={`mailto:${school.email}`}
              >
                <div className='contact-item-container'>
                  <Svg src='/images/icon-mail_outline-24px.svg' />
                  <span className='school-contact-underline'>
                    {school.email}
                  </span>
                </div>
              </ConditionalLink>
              <ConditionalLink condition={school.web} href={school.web}>
                <div className='contact-item-container'>
                  <Svg src='/images/icon-public-24px.svg' />
                  <span className='school-contact-underline'>Página web</span>
                </div>
              </ConditionalLink>
            </ul>
            {school.studentPreferences && (
              <p className='school-preferences'>{school.studentPreferences}</p>
            )}
            <h2>Estudios</h2>
            <ul className='school-education school-list'>
              {school.education?.map(ed => (
                <div key={ed} className='school-list-tag-wrapper'>
                  <li>{ed}</li>
                </div>
              ))}
            </ul>
            <h2>Servicios</h2>
            <ul className='school-services school-list'>
              {school.services?.map(ed => (
                <div key={ed} className='school-list-tag-wrapper'>
                  <li>{ed}</li>
                </div>
              ))}
            </ul>
          </div>
          <div className='school-image'>
            {school.teaserImage?.localFiles?.[0]?.childImageSharp?.fluid && (
              <Img
                fluid={
                  school.teaserImage?.localFiles?.[0].childImageSharp?.fluid
                }
              />
            )}
            <ul className='school-social screen-m'>
              <ConditionalLink
                condition={school.facebook}
                href={school.facebook}
              >
                <Svg width={24} src='/images/logo-facebook.svg' />
              </ConditionalLink>
              <ConditionalLink
                condition={school.instagram}
                href={school.instagram}
              >
                <Svg width={24} src='/images/logo-insta.svg' />
              </ConditionalLink>
              <ConditionalLink condition={school.twitter} href={school.twitter}>
                <Svg width={24} src='/images/logo-twitter.svg' />
              </ConditionalLink>
            </ul>
          </div>
        </div>
        <div className='margin-bot'>
          <Map
            className='school-map'
            zoom={15}
            school={school}
            center={{
              lat: Number(school.latitude),
              lng: Number(school.longitude),
            }}
          />
          <ul className='school-social screen-s'>
            <ConditionalLink condition={school.facebook} href={school.facebook}>
              <Svg width={24} src='/images/logo-facebook.svg' />
            </ConditionalLink>
            <ConditionalLink
              condition={school.instagram}
              href={school.instagram}
            >
              <Svg width={24} src='/images/logo-insta.svg' />
            </ConditionalLink>
            <ConditionalLink condition={school.twitter} href={school.twitter}>
              <Svg width={24} src='/images/logo-twitter.svg' />
            </ConditionalLink>
          </ul>
        </div>
        <div id='school-reviews' className='school-reviews'>
          <div className='school-reviews-header'>
            <div className='review-total'>
              <span className='review-average'>
                {!isNaN(school.avgApprovedReviews) &&
                  Number(school.avgApprovedReviews).toFixed(2) + ' '}
              </span>
              <div>
                <Rating value={Number(school.avgApprovedReviews)} />
              </div>
              <div>
                ({school.countApprovedReviews}{' '}
                {school.countApprovedReviews === 1 ? 'opinión' : 'opiniones'})
              </div>
            </div>
            <ReviewForm schoolId={school.schoolID} />
          </div>
          <ul className='reviews'>
            {school.reviews?.map(review => (
              <Review key={review.id} review={review} />
            )) ?? (
              <p className='no-reviews'>No hay opiniones sobre este colegio</p>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

SchoolDetail.propTypes = {
  data: PropTypes.object,
}

export default SchoolDetail

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    airtable(table: { eq: "zaragoza_schools" }, id: { eq: $id }) {
      data {
        name
        phone
        email
        address
        latitude
        longitude
        postalCode
        city
        province
        avgApprovedReviews
        fundingType
        studentPreferences
        education
        services
        countApprovedReviews
        web
        facebook
        twitter
        instagram
        gobLink
        schoolID
        reviews {
          id
          data {
            date
            kidAge
            reviewerName
            comments
            rating
          }
        }
        teaserImage {
          localFiles {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
