import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from '../utils/strings'

const Map = ({ center, className, school, zoom }) => {
  if (typeof window === 'undefined') {
    return null
  }

  let map
  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false)
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true)

  window.mapCallback = () => {
    setIsGoogleMapLoaded(true)
  }

  const mapRef = useRef()
  const markerRef = useRef()
  const infoWindowRef = useRef()

  const toggleInfoWindow = () => setIsInfoWindowOpen(current => !current)

  // effect to load google map's script
  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      '//maps.googleapis.com/maps/api/js?key=AIzaSyDCbE5ELc8-_grAp_kSXa0P6x3YE72cSAo&callback=mapCallback'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // effect to setup the map, marker and info window
  useEffect(() => {
    if (!window.google) {
      return
    }

    map = new window.google.maps.Map(document.getElementById('school-map'), {
      zoom: zoom,
      center,
      disableDefaultUI: true,
      zoomControl: true,
    })
    mapRef.current = map

    const marker = new window.google.maps.Marker({
      position: center,
      icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
      map,
    })
    markerRef.current = marker

    const infoWindow = new window.google.maps.InfoWindow({
      content: `<strong>${capitalize(school.name)}</strong><br/><span>${
        school.address
      }</span><br/><span>${school.postalCode}, ${school.city}</span>`,
    })
    infoWindowRef.current = infoWindow

    infoWindow.open(map, marker)
    infoWindow.addListener('closeclick', () => {
      setIsInfoWindowOpen(false)
    })

    marker.addListener('click', toggleInfoWindow)
  }, [isGoogleMapLoaded])

  // effect to handle the info window visibility
  useEffect(() => {
    if (isInfoWindowOpen) {
      infoWindowRef.current?.open(mapRef.current, markerRef.current)
    } else {
      infoWindowRef.current?.close()
    }
  }, [isInfoWindowOpen])

  return <div id='school-map' className={className} />
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
  school: PropTypes.object.isRequired,
  zoom: PropTypes.number,
}

Map.defaultProps = {
  className: '',
  zoom: 15,
}

export default Map
