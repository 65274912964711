import React from 'react'
import PropTypes from 'prop-types'

const ButtonWithLoading = ({
 disabled,
 className,
 children,
 isLoading,
 ...otherProps
}) => (
  <button
    {...otherProps}
    className={`${className} ${isLoading ? 'button-loading' : ''}`}
    disabled={disabled || isLoading}
  >
    <span className='with-loading-children'>{children}</span>
  </button>
)

ButtonWithLoading.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

ButtonWithLoading.defaultProps = {
  disabled: false,
  className: '',
  isLoading: false,
}

export default ButtonWithLoading
